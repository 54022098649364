import React, {Component} from 'react';
import Answer from "./Components/Answer";
import Emoticon from "./Components/Emoticon";
import Stress from "./Components/Stress";
import Frame from "./Components/Frame";
import SpeechButton from "./Components/SpeechButton";


class DialogScreen extends Component {
    constructor(props) {
        super(props);
        this.state = {
            selected:null,
            frameAlertClass:'',
            tpicidViewClass:'',
        }

        this.handleAnswer = this.handleAnswer.bind(this);
        this.handleFrameAlert = this.handleFrameAlert.bind(this);
    }

    handleAnswer(aid, atp, aco) {
        if(this.state.selected){
            return;
        }
        this.setState({selected:aid})

        let that = this
        setTimeout(function(){
            that.props.handleAnswer(aid,atp, aco)
            console.log(aid)
        },2000)
        //
    }

    handleFrameAlert(className){
        this.setState({frameAlertClass:className});
    }

    renderTester() {
        let tester = this.props.data.TESTER;
        if (!tester) {
            console.log('no data for rendering tester')
            return;
        }

        let stress = this.props.data.STRESS;
        let frame = this.props.data.FRAME;

        let bubble = '';
        if(tester.TTEXT.TLOOK==='MIND'){
            bubble='mind';
        }
        if(tester.TTEXT.TLOOK==='SPEAK'){
            bubble='speak';
        }

        if(tester.PICID && tester.PICID>0){
            if(this.state.tpicidViewClass == '') {
                this.setState({'tpicidViewClass': 'tpicidview'});
            }
            let picidurl = "/ademon/app/data/tpic/"+tester.PICID;

            if(this.props.idcontext && this.props.idcontext!=='' && this.props.idcontext > 0){
                picidurl += '?idcontext='+parseInt(this.props.idcontext);
            }
            return (
                <>
                    <div className={'tester '+bubble}>
                        <div className="tcontent">{tester.TTEXT.TCONTENT}</div>
                        <div className="emoticon-box">
                            <img className="img-fluid" src={picidurl} alt="emoticon" />
                        </div>
                    </div>

                    <Stress data={stress} disabled={!!this.state.selected} handleAnswer={this.handleAnswer}/>
                    <Frame data={frame} disabled={!!this.state.selected} handleFrameAlert={this.handleFrameAlert}/>
                </>
            )
        }



        return (
            <>
                <div className={'tester '+bubble}>
                    <div className="tcontent">{tester.TTEXT.TCONTENT}</div>

                    <SpeechButton media={tester.TSPKID} text={tester.TTEXT.TCONTENT} main={"true"}/>
                </div>

                <Emoticon id={tester.TEMOTIKONID}/>
                <Stress data={stress} disabled={!!this.state.selected} handleAnswer={this.handleAnswer}/>
                <Frame data={frame} disabled={!!this.state.selected} handleFrameAlert={this.handleFrameAlert}/>
            </>
        )
    }

    renderPersona() {

        let persona = this.props.data.PERSONA;

        if (!persona) {
            console.log('no data for rendering persona')
            return;
        }
        if (persona.PEMOTIKONID=='0'){
            return;
        }
        let answers = persona.PTEXT.ANSWER;

        return (
            <>

                <div className="answers">
                    <div className="answers--inner">
                        {answers.map((answer, i) => {
                            let selected=this.state.selected && answer.AID==this.state.selected
                            return (
                                <Answer key={i} data={answer}
                                        disabled={!!this.state.selected}
                                        selected={selected}
                                        handleAnswer={this.handleAnswer}/>
                            )
                        })}
                    </div>
                </div>
            </>
        )
    }

    render() {

        let className='App frame';
        if(this.state.frameAlertClass){
            className+=' '+this.state.frameAlertClass;
        }
        if(this.state.tpicidViewClass){
            className+=' '+this.state.tpicidViewClass;
        }
        return (
            <div className={className}>

                {this.renderTester()}
                {this.renderPersona()}

            </div>
        );
    }
}

export default DialogScreen;
